// @flow

import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import type { Node } from 'react'
import addRedirectToFirstPage from '../../hocs/addRedirectToFirstPage'
import EmptyList from '../../components/EmptyList'
import Lightbox from '../../components/LightboxGallery'
import BottomPagination from '../../components/Pagination/BottomPagination'
import Loader from '../../components/Loader'
import Modal from '../../components/Modal'
import BrowserStorage from '../../utils/browserStorage'
import { getLocation } from '../../utils/commonSelectors'
import { getBoolean, getCurrentFilters } from '../../utils/routing'
import {
  mailListReset,
  mailListInitiating,
  showModal,
  changeNotesAction,
} from './ThreadsList.actions'
import { checkPending } from '../../components/Emails/EmailPending'
import MailToolbox from './MailToolbox'
import ThreadsListItem from './ThreadsListItem'
import {
  INITIAL_ITEMS_COUNT,
  FROM_CREATE,
  MASS_CREATED,
  HIDE_SUCCESS_MODAL_KEY,
} from './ThreadsList.constants'
import NotesChangeModal from '../../components/Emails/NotesChangeModal'
import SentSuccessModal from '../../components/Emails/SentSuccessModal'
import NewTable from '../../components/NewTable/NewTable'
import NewTableBody from '../../components/NewTable/NewTableBody'
import NewTableHeader from '../../components/NewTable/NewTableHeader'
import NewTableHeaderRow from '../../components/NewTable/NewTableHeaderRow'
import NewTableHeaderCell from '../../components/NewTable/NewTableHeaderCell'

import Warning from '../../components/Warning'

type MailListInitiatingAction = { type: string }

export type MailItem = {
  avatar: string,
  created: string,
  files: Array<Object>,
  is_system: boolean,
  notes: ?string,
  owner_obj: Object,
  text: string,
  thread: string,
  uuid: string,
}

type Props = {
  cantCreateMessage: boolean,
  deleteThreads: Object => void,
  fileIdToBeShown: number,
  files: Array<Object>,
  folder: 'inbox' | 'sent',
  isSearch?: boolean,
  location: Object,
  mailList: Array<MailItem>,
  mailListInitiating: Object => MailListInitiatingAction,
  mailListReset: () => void,
  meta: Object,
  metaFiles: Object,
  modal: Object,
  permissions: Object,
  push: string => void,
  restoreThreads: Object => void,
  selected: Array<string>,
  showModal: (?Node) => void,
  startInitiatingGetFiles: (number, string, Object) => void,
  t: string => string,
  toggle: string => void,
  toggleAll: () => void,
  type: string,
  working: boolean,
}

type State = {
  lightboxIndex: ?number,
  modal?: any,
  softArchived: boolean,
  successModalClosed: boolean,
  tab: string,
}

class ThreadsList extends Component<Props, State> {
  state = {
    lightboxIndex: null,
    modal: null,
    softArchived: false,
    folderExternalIds: null,
    excludeFolderExternalIds: null,
    tab: 'inbox',
    successModalClosed: false,
  }

  componentDidMount() {
    this.init()
  }

  componentDidUpdate(prevProps, prevState) {
    const { fileIdToBeShown, files, folder, location, isSearch, query } =
      this.props
    const filters = getCurrentFilters(location)
    const softArchived = getBoolean(filters, 'soft_archived')
    const folderExternalIds = filters.folder_external_ids
    const excludeFolderExternalIds = filters.exclude_folder_external_ids

    this.openSuccessModal()

    if (softArchived !== this.state.softArchived) {
      this.setState({ softArchived })
    }

    if (
      folderExternalIds !== this.state.folderExternalIds ||
      excludeFolderExternalIds !== this.state.excludeFolderExternalIds
    ) {
      this.setState({ folderExternalIds, excludeFolderExternalIds })
    }

    if (prevProps.location.search !== location.search) {
      this.setPage({ folder, ...filters, isSearch, query })
    }

    if (this.state.lightboxIndex === null && files !== prevProps.files) {
      const fileIndex = files.findIndex(
        element => element.id === fileIdToBeShown
      )
      this.setState({
        lightboxIndex: fileIndex !== -1 ? fileIndex : null,
      })
    }

    if (prevState.successModalClosed === this.state.successModalClosed) {
      checkPending(this.props.mailList, this.init)
    }
  }

  init = () => {
    const { location, folder, isSearch, query } = this.props
    const filters = getCurrentFilters(location)

    this.setState({
      softArchived: getBoolean(filters, 'soft_archived'),
      folderExternalIds: filters.folder_external_ids,
      excludeFolderExternalIds: filters.exclude_folder_external_ids,
      successModalClosed: false,
    })

    window.scrollTo({ top: 0 })

    if (filters.page) {
      this.props.mailListInitiating({ folder, ...filters, isSearch, query })
    }
  }

  componentWillUnmount() {
    this.props.mailListReset()
  }

  setPage = params => {
    this.props.mailListInitiating(params)
  }

  create = () => {
    this.props.push('/mail/create')
  }

  handleAdvancedSearch = () => {
    const { location } = this.props
    const queryParams = new URLSearchParams(location.search)
    queryParams.set('page', '1')
    queryParams.set('is_opensearch', '1')
    this.props.push(`${location.pathname}?${queryParams.toString()}`)
  }

  hideModal = () => {
    this.props.showModal(null)
  }

  openNotesModal = thread => {
    this.props.showModal(
      <NotesChangeModal
        isOpen
        thread={thread}
        onSubmit={this.handleSubmit}
        onClose={this.hideModal}
      />
    )
  }

  okSuccessModal = hide => {
    BrowserStorage.set(HIDE_SUCCESS_MODAL_KEY, hide)
    this.hideSuccessModal()
  }

  openSuccessModal = () => {
    if (
      !this.props.working &&
      !this.props.modal &&
      !this.state.successModalClosed &&
      !JSON.parse(BrowserStorage.get(HIDE_SUCCESS_MODAL_KEY)) &&
      BrowserStorage.get(FROM_CREATE) &&
      JSON.parse(BrowserStorage.get(FROM_CREATE))
    ) {
      this.props.showModal(
        <SentSuccessModal
          isOpen
          isMass={
            BrowserStorage.get(MASS_CREATED) &&
            JSON.parse(BrowserStorage.get(MASS_CREATED))
          }
          onOk={this.okSuccessModal}
          onClose={this.hideSuccessModal}
        />
      )
      BrowserStorage.set(FROM_CREATE, false)
      BrowserStorage.set(MASS_CREATED, false)
    }
  }

  hideSuccessModal = () => {
    this.setState({ successModalClosed: true })
    this.hideModal()
  }

  handleSubmit = (uuid, notes) => {
    this.props.changeNotesAction(uuid, notes)

    this.hideModal()
  }

  renderEmpty = () => {
    const {
      isSearch,
      permissions: { can_create: canCreate },
    } = this.props

    return (
      <EmptyList
        btnText={this.props.t('WriteAMessage')}
        canAdd={canCreate}
        icon='email'
        title={this.props.t(isSearch ? 'MailsListIsEmpty' : 'NoMailItems')}
        onClick={this.create}
      />
    )
  }

  renderSearchWarning = () => {
    const { isSearch, location } = this.props
    const filters = getCurrentFilters(location)

    return isSearch && filters.is_opensearch !== '1' ? (
      <>
        <Warning
          noArrow
          className='search-warning'
          boxClassName='search-warning-box'
          text={this.props.t('SearchWarning')}
        />
        <div className='search-warning-buttons'>
          <span className='red-link' onClick={this.handleAdvancedSearch}>
            {this.props.t('SearchAdvancedLink')}
          </span>
        </div>
      </>
    ) : null
  }

  renderMailListItem = thread => {
    const { softArchived, folderExternalIds, excludeFolderExternalIds } =
      this.state
    const {
      selected,
      work_as_email_client,
      isSearch,
      folder,
      permissions: { can_update_starred },
    } = this.props

    const params = work_as_email_client
      ? folderExternalIds
        ? `&folder_external_ids=${folderExternalIds}`
        : `&exclude_folder_external_ids=${excludeFolderExternalIds}`
      : ''

    return (
      <ThreadsListItem
        isSearch={isSearch}
        canUpdateStarred={can_update_starred}
        key={thread.uuid}
        thread={thread}
        softArchived={softArchived}
        folder={folder}
        selected={selected}
        init={this.init}
        params={params}
        onChangeNotes={this.openNotesModal}
      />
    )
  }

  render() {
    const {
      mailList,
      meta,
      files,
      working,
      modal,
      selected,
      folder,
      permissions,
      isSearch,
      query,
    } = this.props

    const isEmpty = !working && !mailList.length
    const pathname = `mails/${folder}`

    if (isEmpty) {
      return (
        <>
          {this.renderSearchWarning()}
          {this.renderEmpty()}
        </>
      )
    }

    const { softArchived } = this.state

    return (
      <>
        {this.renderSearchWarning()}
        {!working && (
          <MailToolbox
            folder={folder}
            softArchived={softArchived}
            selected={selected}
            mailList={mailList}
            meta={meta}
            pathname={pathname}
            permissions={permissions}
            isSearch={isSearch}
            query={query}
          />
        )}
        <div className='inbox__container'>
          {mailList && !working ? (
            <NewTable>
              <NewTableHeader>
                <NewTableHeaderRow>
                  <NewTableHeaderCell style={{ width: '20%' }} />
                  <NewTableHeaderCell style={{ width: '70%' }} />
                  <NewTableHeaderCell style={{ width: '10%' }} />
                </NewTableHeaderRow>
              </NewTableHeader>
              <NewTableBody>
                {mailList.map(this.renderMailListItem)}
              </NewTableBody>
            </NewTable>
          ) : (
            <Loader text={false} type='big' />
          )}
        </div>
        {!working && meta && meta.count > INITIAL_ITEMS_COUNT && (
          <BottomPagination
            classes='paginator paginator--middle'
            meta={meta}
            basePage={pathname}
          />
        )}
        <Lightbox
          newGallery
          activeIndex={this.state.lightboxIndex}
          images={files}
          onClose={() => this.setState({ lightboxIndex: null })}
        />
        <Modal isOpen={!!modal} onRequestClose={this.hideModal}>
          {modal}
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...state.threadsList,
  location: getLocation(state),
})

const mapDispatchToProps = {
  mailListReset,
  mailListInitiating,
  showModal,
  push,
  changeNotesAction,
}

export default compose(
  withTranslation('Mail'),
  connect(mapStateToProps, mapDispatchToProps),
  addRedirectToFirstPage
)(ThreadsList)
